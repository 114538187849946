// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dashboard: {
    title: "タイトル",
    persona: "ペルリア",
    published: "公開済",
    unpublished: "未公開",
  },
  seedSetting: {
    title: "シード設定",
    labelconvert:
      "ペルリアからアルカナへの変換時、乱数から以下の計算式に従いseed値を算出。",
    labelrandom: "乱数テーブル要素数",
    labelmagnification: "倍率",
    labelprobability: "確率",
    labelformat: "Seed値＝乱数テーブルから抽選した倍率×トークンの総合値",
    labelexpectedmultiple: "期待倍率",
    labelformula: "（期待倍率=Σ[k=1, n]倍率×確率）",
    labeltotal:
      '合計確率は <span class="text-primary fs-25-px">100%</span>になる必要があります。',
    labeltotalprobability: `合計確率`,
    labellackprobability: `足りない確率`,
  },
  group: {
    title: "グループリスト",
    placeholdersearch: "グループ名やグループ番号にて検索",
    groupname: "グループ名",
    groupnumber: "グループ番号",
    numberofpersonas: "ペルリア数",
    createddate: "作成日",
    action: "アクション",
  },
  type: {
    title: "タイプリスト",
    placeholdersearch: "タイプ名やタイプ番号にて検索",
    typename: "タイプ名",
    typenumber: "タイプ番号",
    groupname: "グループ名",
    createddate: "作成日",
    numberofpersonas: "ペルリア数",
    action: "アクション",
  },
  management: {
    title: "ペルリア管理",
    placeholdersearch: "ユーザー名やウォレットアドレスで検索",
    typename: "タイプ名",
    typenumber: "タイプ番号",
    groupname: "グループ名",
    createddate: "作成日",
    numberofpersonas: "ペルリア数",
    action: "アクション",
  },
  users: {
    title: "ユーザー管理",
    user_name_number: "ユーザーNo／ユーザー名",
    wallet_addres: "ウォレットアドレス",
    number_of_hold_personas: "ペルリア保有数",
    amount_in_jp: "金額/JPY",
    registration_date: "ユーザー登録日",
  },
  button: {
    create: "作成",
  },
  menu: {
    dashboard: "ダッシュボード",
    title: "タイトル",
    persona: "ペルリア",
    drawchain: "ドローチェーン",
    update_history: "アップデート履歴",
    marketplace: "マーケットプレイス",
    user_management: "ユーザー管理",
    setting: "設定",
    signout: "サインアウト",
  },
  titles: {
    title: "タイトルリスト",
    placeholdersearch: "タイトル名やタイトルIDで検索",
    button: "タイトル新規作成",
    id: "タイトルID",
    name: "タイトル名",
    no_of_type: "ペルリア種類数",
    no_of_persona: "ペルリア数",
    updatedAt: "タイトル更新日",
    action: "アクション",
    setting: "タイトル設定",
  },
  titleSetting: {
    title: "タイトル設定",
    name: "タイトル名",
    id: "タイトルID",
    id_secondary: "自動出力",
    value: "AURA ／Gia Change",
    value_secondary: "アルカナジェネレーターを呼び出す値",
    value_placeholder: "数値を入力",
    custom_parameter: "カスタムパラメータ1",
    custom_parameter_secondary:
      "タイトルの配下となる全ペルリアに対して任意のパラメータを設定",
    parameter_type: "パラメータの型",
    parameter_type_secondary: "（整数, 実数, 文字列）",
    parameter_type_placeholder: "データ型を選択",
    parameter_name: "パラメータ名",
    parameter_name_placeholder: "○○力など",
    parameter_unit: "パラメータの単位",
    parameter_unit_secondary: "単位は無くても可",
    parameter_unit_placeholder: "mile, kg など",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
    delete_btn: "タイトル削除",
    not_transf: "譲渡不可",
  },
  titleCreate: {
    title: "タイトル新規作成",
    name: "タイトル名",
    name_placeholder: "グループ名やサービス名など",
    id: "タイトルID",
    id_secondary: "自動出力",
    value: "AURA ／Gia Change",
    value_secondary: "アルカナジェネレーターを呼び出す値",
    value_placeholder: "数値を入力",
    custom_parameter: "カスタムパラメータ1",
    custom_parameter_secondary:
      "タイトルの配下となる全ペルリアに対して任意のパラメータを設定",
    parameter_type: "パラメータの型",
    parameter_type_secondary: "（整数, 実数, 文字列）",
    parameter_type_placeholder: "データ型を選択",
    parameter_name: "パラメータ名",
    parameter_name_placeholder: "○○力など",
    parameter_unit: "パラメータの単位",
    parameter_unit_secondary: "単位は無くても可",
    parameter_unit_placeholder: "mile, kg など",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
    not_transf: "譲渡不可",
  },
  personas: {
    title: "ペルリアリスト",
    placeholdersearch: "ペルリア名やペルリアNoで検索",
    button: "ペルリア新規作成",
    no: "ペルリアNo",
    name: "ペルリア名",
    type_name: "タイトル名",
    published: "公開数 / ペルリア数",
    price: "価格 / JPY",
    action: "アクション",
    detail: "ペルリア詳細",
  },
  personaDetail: {
    title: "ペルリア詳細",
    cancel_btn: "キャンセル",
    update_btn: "アップデート",
    title_name: "タイトル名",
    token_id: "トークンID",
    token_id_secondary: "自動出力",
    name: "ペルリア名",
    creator_name: "クリエイター名",
    quantity: "QTY（発行数）",
    published: "マーケットプレイス公開数",
    price: "現在の販売価格",
    image: "画像",
    parameter: "カスタムパラメータ 1",
    parameter_secondary: "※タイトルで定義したパラメータ",
    leadership: "統率力",
    political_power: "政治力",
    leg_strength: "脚力",
    charisma: "カリスマ性",
    description: "Description（ペルリアの説明文）",
    description_secondary:
      "ペルリアに対する説明文を記載。カード状の裏側に表示される",
    description_placeholder: "ペルリアに対する説明文を記載",
    status: "Status（ベースパラメータ）",
    status_secondary:
      "ペルリアの持つ基礎パラメータ。ANMコストやドローチェーンなどに影響",
    cost: "必要ANM（アニマ）コスト",
    cost_secondary: "※ベースパラメータによって必要なANM変化",
    absorb: {
      label: "Absorb（食べる）",
      contract: "Absorb contract",
    },
    absorb_secondary: "アルカナを吸収するスマートコントラクトのON／OFF",
    draw_chain: {
      label: "Draw chain（ドローチェーン）",
      contract: "Draw chain Contract",
      id: "Draw chain ID / code",
      name: "ドローチェーン名",
      name_placeholder: "チャッピーワンのフードボーナス",
      required: "ドローに必要な",
      required_secondary: "ドローチェーンが実行できるようになる FOR の値",
      no: "ドローできる回数",
      no_secondary: "回",
      description: "ドロー対象の説明",
      description_placeholder:
        "ネコダービーのアイテム「スーパーフード」が10個もらえる！",
      placeholder: "値を入力",
    },
    draw_chain_secondary:
      "ペルリアを使って特定のアイテムを引き出すスマートコントラクトのON／OFF",
    element: {
      label: "Elements（属性）",
    },
    element_secondary: "ペルリアの持つ属性・カードゲームなどに影響",
    place_of_birth: "Birthplace（出生地）",
    place_of_birth_secondary: "スクエアキーによって定義",
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルリアの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルリアの持つ特殊能力を自由に定義",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルリアに称号を自由に定義",
    parameter_placeholder: "文字列入力",
    tag: "Tag（検索タグ）",
    tag_secondary: "ペルリアが検索される際のキーワード設定",
  },
  personaMarketPreview: {
    title: "マーケットプレイス公開設定",
    public_btn: "公開／売出",
    cancel_btn: "キャンセル",
    private_marketplace: "プライベートマーケットプレイス",
    published_on: "OCTILLION（オクティリオン） での公開",
    sale_volume: "販売数",
    sale_volume_placeholder: "販売数を入力",
    current_sale: "現在の販売数",
    disclosed: "未公開数",
    element: {
      label: "Elements（属性別）",
    },
    selling_price: {
      label: "販売価格（売りのオファー）",
      jp: "JPY（日本円）",
      anima: "ANIMA（アニマ）",
    },
    total: "合計",
  },
  personaUp: {
    title: "内部値アップデート",
    cancel_btn: "キャンセル",
    update_btn: "アップデート実行",
    title_name: "タイトル名",
    token_id: "トークンID",
    token_id_secondary: "自動出力",
    custom_parameter: {
      label: "カスタムパラメータ 1",
      current_value: "現在の値",
      changed_value: "変更後の値",
      leadership: "統率力",
      political_power: "政治力",
      leg_strength: "脚力",
      charisma: "カリスマ性",
    },
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルリアの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルリアの持つ特殊能力を自由に定義",
    parameter_placeholder: "文字列入力",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルリアに称号を自由に定義",
  },
  titleSelect: {
    title: "タイトル選択",
    search_placeholder: "タイトル名やタイトルIDで検索",
    cancel_btn: "キャンセル",
    id: "タイトルID",
    name: "タイトル名",
    no_of_type: "ペルリア種類数",
    no_of_persona: "ペルリア数",
    createdAt: "作成日",
    action: "アクション",
    select_btn: "このタイトルを選択",
  },
  personaCreate: {
    title: "ペルリア新規発行",
    search_placeholder: "タイトル名やタイトルIDで検索",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
    delete_btn: "削除",
    issue_btn: "ペルリア発行",
    title_name: "タイトル名",
    name: "ペルリア名",
    name_placeholder: "ペルリア名を入力",
    token_id_secondary: "自動出力",
    creator_name: "クリエイター名",
    creator_name_placeholder: "クリエイター名を入力",
    quantity: "QTY（発行数）",
    quantity_placeholder: "発行数を入力",
    image: "画像",
    parameter: "カスタムパラメータ 1",
    parameter_secondary: "※タイトルで定義したパラメータ",
    // leadership: "統率力",
    // political_power: "政治力",
    // leg_strength: "脚力",
    // charisma: "カリスマ性",
    description: "Description（ペルリアの説明文）",
    description_secondary:
      "ペルリアに対する説明文を記載。カード状の裏側に表示される",
    description_placeholder: "ペルリアに対する説明文を記載",
    status: "Status（ベースパラメータ）",
    status_secondary: "ペルリアの持つ基礎パラメータ。ドローチェーンなどに影響",
    cost: "ANM（アニマ）コスト ",
    cost_secondary:
      "ベースパラメータの設定に必要なGas。ベースパラメータの合計値に影響",
    cost_current: "現在のANM残高",
    parameter_label: "パラメータ振り分け",
    parameter_label_secondary: "５つのパラメータの振り分けパーセンテージを設定",
    parameter_total: "パラメータ合計値",
    parameter_note:
      "５つのパラメータに割り当て可能な合計値。ANMコストにより自動算出",
    parameter_warning:
      "※ ベースパラメータの設定値は、実際には誤差が出ることがあります。",
    absorb: {
      label: "Absorb（食べる）",
      contract: "Absorb contract",
    },
    absorb_secondary: "アルカナを吸収するスマートコントラクトのON／OFF",
    draw_chain: {
      label: "Draw chain（ドローチェーン）",
      contract: "Draw chain Contract",
      id: "Draw chain ID / code",
      name: "ドローチェーン名",
      name_placeholder: "ドローチェーン名を入力",
      required: "ドローに必要な",
      required_secondary: "ドローチェーンが実行できるようになる FOR の値",
      no: "ドローできる回数",
      no_secondary: "回",
      description: "ドロー対象の説明",
      description_placeholder:
        "ネコダービーのアイテム「スーパーフード」が10個もらえる！",
      placeholder: "値を入力",
    },
    draw_chain_secondary:
      "ペルリアを使って特定のアイテムを引き出すスマートコントラクトのON／OFF",
    element: {
      label: "Elements（属性）",
    },
    element_secondary: "ペルリアの持つ属性・カードゲームなどに影響",
    place_of_birth: "Birthplace（出生地）",
    place_of_birth_secondary: "スクエアキーによって定義",
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルリアの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルリアの持つ特殊能力を自由に定義",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルリアに称号を自由に定義",
    upload_btn: "アップロード",
    parameter_placeholder: "文字列入力",
    validation: {
      imageSize: "ファイルサイズを2MB以下にする必要があります",
    },
    tag: "Tag（検索タグ）",
    tag_secondary:
      "ペルリアが検索される際のキーワード設定。日本語の下にある英語のキーワドは英訳になり、日本語のキーワドと連動されます。",
  },
  marketplace: {
    title: "マーケットプレイス情報",
    address: "Address",
    public_status: "公開ステータス",
    sales: "売上高",
    primary_on_sale: "プライマリ販売中",
    total_selling_offer_amount: "売りオファーの総額",
    secondary_open: "セカンダリ公開中",
    this_month_sale: "今月の売上 ",
    primary_sales: "プライマリ販売",
    secondary_commission: "セカンダリ手数料",
    total_so_far: "これまでの累計",
  },
  drawchains: {
    title: "ドローチェーン",
    placeholdersearch: "ドローチェーン名で検索",
    button: "新規作成",
    id: "ドローチェーンID",
    name: "ドローチェーン名",
    perlia_title: "ペルリアタイトル",
    perlia_name: "ペルリア名",
    created_at: "作成日",
  },
  drawchain: {
    title: "ドローチェーン詳細",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
    sub_title: "Draw chain（ドローチェーン） ",
    sub_title_secondary:
      "ペルリアを使って特定のアイテムを引き出すスマートコントラクトの設定",
    perlia_title: "ペルリアタイトル名",
    perlia_title_placeholder: "ペルリアタイトル選択",
    perlia_name: "ペルリア名",
    perlia_name_placeholder: "ペルリア選択",
    id: "Draw chain ID / code",
    auto: "自動出力",
    status: "ステータス",
    status_secondary: "ANICANA MALL θυρα上のドローチェーン表示有無設定",
    required_status: "ドローに必要なステータス",
    base_status: "基本ステータス",
    no_of_draw: "ドローできる回数",
    no_of_draw_placeholder: "値を入力",
    times: "回",
    by_mail: "郵送の有無",
    is_burn: "ドロー発動時のBurn",
    name: "ドローチェーン名",
    name_placeholder: "ドローチェーン名を60文字以内で入力してください。",
    thumbnail: "サムネイルで使用されるメイン画像",
    vertical_thumbnail: "トレンド表示で表示される縦型のサムネイル画像",
    description: "Description",
    description_secondary: "ドロー対象の説明",
    description_placeholder: "ドロー対象の説明を入力してください。",
    notice: "※ペルリアを選択すると以下の設定項目が表示される",
    vertical_thumbnail_placeholder: "トレンドで表示される縦型のサムネイル",
    thumbnail_placeholder: "サムネイルで使用されるメインイメージ",
    perlia_image: "対象ペルリア画像",
  },
  drawchainCreate: {
    title: "ドローチェーン新規作成",
  },
  choosePerria: {
    title: "CHOOSE PERRIA TITLE",
    placeholder: "ペルリアタイトルIDやタイトル名で検索",
    id: "タイトルID",
    name: "タイトル名",
  },
};
