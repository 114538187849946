import { createSlice } from "@reduxjs/toolkit";

const personaSlice = createSlice({
  name: "personas",
  initialState: {
    personas: [],
    selectedPersona: null,
    isPersonaPublished: false,
  },
  reducers: {
    appendPersona(state, action) {
      state.personas.push(action.payload);
    },
    setPersonas(state, action) {
      state.personas = state.personas.concat(action.payload);
    },
    setSelectPersona(state, action) {
      state.selectedPersona = action.payload;
    },
    setIsPersonaPublished(state, action) {
      state.isPersonaPublished = !!action.payload;
    },
  },
});

export const {
  appendPersona,
  setPersonas,
  setSelectPersona,
  setIsPersonaPublished,
} = personaSlice.actions;

export default personaSlice.reducer;
