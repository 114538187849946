import React, { createContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  storeErrorModal,
  storeErrorStatus,
  storeAccessToken,
} from "../services/storage";
import { fetchData } from "../services/fetch";
import { useDispatch } from "react-redux";
import { setErrorCode, setErrorMessage } from "../slices/login";

export const AuthContext = createContext("");

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //Use tates
  const [user, setUser] = useState(); //user data
  const [loginLoading, setLoginLoading] = useState(false); //login loading progress

  //fetch user once when start the app
  useEffect(() => {
    const unloadRoutes = ["/login"];
    if (!unloadRoutes.includes(location.pathname)) {
      fetchData({ url: "api/user" }).then((res) => {
        setUser(res.data);
      });
    }
    //eslint-disable-next-line
  }, []);

  const fetchAuthUser = () => {
    fetchData({ url: "api/user" }).then((res) => {
      setUser(res.data);
    });
  }

  /** Auth Functions */
  //login function
  const login = (data) => {
    setLoginLoading(true);
    fetchData({
      url: "api/login",
      data: {
        email: data.email,
        password: data.password,
      },
      method: "post",
    })
      .then((res) => {
        // alert("success login");
        console.log(res);
        storeErrorModal("");
        storeErrorStatus("");
        storeAccessToken(res.data.token);
        setUser(res.data.user);
        if (res.status === 200) {
          dispatch(setErrorCode(""));
          dispatch(setErrorMessage(""));
        }
        navigate("/");
      })
      .catch((err) => {
        dispatch(setErrorCode(err.response.status));
        dispatch(setErrorMessage("メールアドレスとパスワードが一致しません"));
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  const logout = () => {
    fetchData({
      url: "api/logout",
      method: "post",
    }).then((res) => {
      storeAccessToken("");
      navigate("/login");
    });
  };

  //use Memo
  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      loginLoading,
      logout,
      fetchAuthUser
    }),
    //eslint-disable-next-line
    [user, loginLoading]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
