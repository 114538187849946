import { useNavigate, useParams } from "react-router-dom";
import Button, { CancelButton } from "../../components/Button";
import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { translations } from "../../services/localization";
import { ConfirmModal, InfoModal, LoadingModal } from "../../components/Modal";
import { useContext, useState } from "react";
import { NumberInput, SelectInput, TextInput } from "../../components/Form";
import {
  ABSIcon,
  CopyIcon,
  DFTIcon,
  EXPIcon,
  FORIcon,
  INTIcon,
  MNDIcon,
  PenIcon,
} from "../../assets/icons";
import { useMutation, useQuery } from "react-query";
import { fetchData } from "../../services/fetch";
import { AppContext } from "../../context/appContext";
import { queryClient } from "../../App";

const Drawchain = () => {
  const contents = translations.drawchain;
  const { id } = useParams();
  const navigate = useNavigate();
  const { setUnexpectedErrorModal } = useContext(AppContext);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [modal, setModal] = useState({ show: false, message: "" });
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [progress, setProgress] = useState(0);

  const { isLoading, data: drawchainData } = useQuery(
    ["get-drawchain", id],
    () =>
      fetchData({
        url: `api/draw-chain/${id}`,
      }),
    {
      onSuccess: (response) => {
        const data = response?.data?.draw_chain;
        if (data) {
          setName(data.title);
          setDescription(data.description);
        }
      },
      onError: (error) => {
        setUnexpectedErrorModal(true);
        if (error.response.status === 404) {
          navigate("/drawchains");
        }
      },
    }
  );

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    async (data) =>
      fetchData({
        url: `api/draw-chain/${id}`,
        method: "put",
        data: data,
      }),
    {
      onError: async (err) => {
        setShowLoadingModal(false);
        setUnexpectedErrorModal(true);
      },
      onSuccess: async (data) => {
        queryClient.cancelQueries(["get-drawchain", id]);
        queryClient.setQueryData(["get-drawchain", id], () => data);
        setProgress(100);
      },
    }
  );

  const handleUpdate = () => {
    if (!name || !description) {
      setModal({ show: true, message: "必須項目を埋めてください。" });
      return;
    }
    setShowLoadingModal(true);
    setProgress(20);
    update({ title: name, description });
  };

  let drawchain = drawchainData?.data?.draw_chain;

  const isDirty = () => {
    return description !== drawchain.description || name !== drawchain.title;
  };

  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={contents.title}
        className="content-wide-title"
        topSpace={
          <div className="d-flex justify-content-end mb-6-px gap-3">
            <CancelButton
              text={contents.cancel_btn}
              width="173px"
              height="40px"
              onClick={() =>
                isDirty() ? setShowCancelModal(true) : navigate("/drawchains")
              }
            />
            <Button
              text={contents.keep_btn}
              width="173px"
              height="40px"
              onClick={handleUpdate}
              disabled={isUpdateLoading}
            />
          </div>
        }
        marginLeft="12px"
      >
        {isLoading ? (
          <em>Loading...</em>
        ) : (
          <div className="h-100 mx-36-px my-10-px">
            <h2 className="">
              <span className="fs-18-px font-notosans-jp text-white mr-30-px">
                {contents.sub_title}
              </span>
              <span className="fs-14-px font-notosans-jp-light text-light-primary">
                {contents.sub_title_secondary}
              </span>
            </h2>
            <section className="ml-18-px mt-30-px">
              <div className="d-flex align-items-center mb-30-px">
                <div className="d-flex align-items-center gap-30-px flex-grow-1">
                  <label className="font-notosans-jp label-text">
                    {contents.perlia_title}
                  </label>
                  {/* <TextInput
                    width="196px"
                    className="button-input"
                    inputClass="text-center text-highlight fs-14-px text-form-control font-ethno-light"
                    inactive
                    value={
                      drawchain.group_name || contents.perlia_title_placeholder
                    }
                    type="button"
                    valueExist={drawchain.group_name ? 1 : 0} // for font family style
                  /> */}
                  <div className="button-select-persona disabled">
                    <button
                      className="text-highlight fs-12-px font-ethno-normal text-truncate"
                      disabled
                    >
                      {drawchain.group_name ||
                        contents.perlia_title_placeholder}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-30-px flex-grow-1">
                  <label className="font-notosans-jp label-text">
                    {contents.perlia_name}
                  </label>
                  <TextInput
                    width="196px"
                    inputClass="text-highlight text-center fs-12-px font-ethno-normal"
                    inactive
                    value={drawchain.persona_type.persona_type_name}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-30-px">
                <label className="font-notosans-jp label-text">
                  {contents.id}
                </label>
                <p
                  className="font-ethno-light fs-18-px text-gray ml-150-px"
                  onClick={() => {
                    navigator.clipboard.writeText(drawchain.drawchain_id);
                    setModal({
                      show: true,
                      message: "クリップボードにコピーしました。",
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {drawchain.drawchain_id} <CopyIcon />
                </p>
                <p className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                  {contents.auto}
                </p>
              </div>
              <div className="d-flex align-items-center mb-30-px">
                <div className="d-flex align-items-center gap-30-px flex-grow-1">
                  <label className="font-notosans-jp label-text mr-20-px">
                    {contents.status}
                  </label>
                  <SelectInput
                    id="status"
                    value={drawchain.can_draw}
                    options={[
                      { label: "OPEN", value: 1 },
                      { label: "CLOSED", value: 0 },
                      { label: "FINISHED", value: 2 },
                    ]}
                    placeholder={contents.status}
                    styles={{ width: 196 }}
                    className="text-highlight text-center fs-13-px font-ethno-normal center-placeholder"
                    disabled
                  />
                  <span className="fs-14-px font-notosans-jp-light text-light-primary">
                    {contents.status_secondary}
                  </span>
                </div>
              </div>
              <div className="mb-30-px d-flex gap-3">
                <div className="flex-fill">
                  <p
                    className="font-notosans-jp label-text mb-4"
                    style={{ height: 40, alignContent: "center" }}
                  >
                    {contents.perlia_image}
                  </p>
                  <div className="persona-img-container img-container">
                    <img src={drawchain.persona_type.image_uri} alt="" />
                  </div>
                </div>
                <div className="flex-fill">
                  <label className="font-notosans-jp label-text my-2 mr-30-px">
                    {contents.base_status}
                  </label>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <FORIcon /> FOR
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.persona_type.for_parameter}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <ABSIcon /> ABS
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.persona_type.abs_parameter}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <DFTIcon /> DFT
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.persona_type.def_parameter}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <MNDIcon /> MND
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.persona_type.month_parameter}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <INTIcon /> INT
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.persona_type.int_parameter}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <EXPIcon /> EXP
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.persona_type.exp_parameter}
                      disabled
                    />
                  </div>
                </div>
                <div className="flex-fill">
                  <label className="font-notosans-jp label-text my-2 mr-30-px">
                    {contents.required_status}
                  </label>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <FORIcon /> FOR
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.for}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <ABSIcon /> ABS
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.abs}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <DFTIcon /> DFT
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.dft}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <MNDIcon /> MND
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.mnd}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <INTIcon /> INT
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.int}
                      disabled
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-35 mr-10-px">
                      <EXPIcon /> EXP
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={drawchain.exp}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mb-30-px">
                <label className="font-notosans-jp label-text mr-50-px">
                  {contents.no_of_draw}
                </label>
                <TextInput
                  width="112px"
                  inputClass="fs-20-px center-placeholder text-center"
                  inactive
                  placeholder={contents.no_of_draw_placeholder}
                  value={drawchain.draw_count}
                  disabled
                />
                <p className="fs-17-px text-white ml-10-px">{contents.times}</p>
              </div>
              <div className="d-flex align-items-center mb-30-px">
                <label className="font-notosans-jp label-text mr-50-px">
                  {contents.by_mail}
                </label>
                <div className="ml-50-px">
                  <span
                    className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                    style={{ width: 110 }}
                  >
                    {drawchain.can_mail ? "ON" : "OFF"}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-30-px">
                <label className="font-notosans-jp label-text mr-35-px">
                  {contents.is_burn}
                </label>
                <div>
                  <span
                    className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                    style={{ width: 100 }}
                  >
                    {drawchain.burn ? "する" : "しない"}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-30-px">
                <label className="font-notosans-jp label-text mr-50-px">
                  {contents.name}
                </label>
                <TextInput
                  width="416px"
                  className="ml-50-px"
                  inputClass="fs-15-px"
                  inactive
                  rightIcon={<PenIcon />}
                  placeholder={contents.name_placeholder}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-start mb-30-px gap-100-px">
                <div>
                  <p className="font-notosans-jp label-text mb-20-px">
                    {contents.thumbnail}
                  </p>
                  <div className="drawchain-thumbnail-container img-container">
                    <img src={drawchain.image_url} alt="Selected thumbnail" />
                  </div>
                </div>
                <div>
                  <p className="font-notosans-jp label-text mb-20-px">
                    {contents.vertical_thumbnail}
                  </p>
                  <div className="drawchain-v-thumbnail-container img-container">
                    <img
                      src={drawchain.background_image_url}
                      alt="Selected thumbnail"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-start mb-30-px">
                <label className="font-notosans-jp label-text mr-50-px mt-10-px">
                  {contents.description} <br /> {contents.description_secondary}
                </label>
                <TextInput
                  className="ml-30-px"
                  inputClass="fs-16-px"
                  isTextArea
                  width="641.1px"
                  height="118px"
                  inactive
                  rightIcon={<PenIcon />}
                  placeholder={contents.description_placeholder}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </section>
          </div>
        )}
      </ContentWrapper>
      <ConfirmModal
        isOpen={showCancelModal}
        onCancel={() => {
          setShowCancelModal(false);
          setTimeout(() => {
            navigate("/drawchains");
          }, 500);
        }}
        onConfirm={() => {
          setShowCancelModal(false);
          handleUpdate();
        }}
      >
        一部の変更は保存されていません。
        <br />
        変更を保存しますか？
      </ConfirmModal>
      <InfoModal
        isOpen={modal.show}
        onAction={() => {
          if (modal.goToList) {
            setTimeout(() => {
              navigate("/drawchains");
            }, 500);
          }
          setModal({ ...modal, show: false });
        }}
      >
        {modal.message}
      </InfoModal>
      <LoadingModal
        className="generate-loading"
        isOpen={showLoadingModal}
        onAction={() => {
          setShowLoadingModal(false);
          setModal({
            show: true,
            message: "保存しました。",
            goToList: true,
          });
        }}
        progress={progress}
        withAction={false}
      />
    </AppWrapper>
  );
};

export default Drawchain;
