import React, { useState, useContext } from "react";
import BackgroundDots from "../assets/images/background_green_effect.svg";
import LoginBox from "../layouts/LoginBox";
import { LoginInput } from "../components/Form";
import LoginMask from "../components/LoginMask";
import { AppContext } from "../context/appContext";
import { AuthContext } from "../context/authContext";
import UserIcon from "../assets/icons/user-solid.svg";
import LockIcon from "../assets/icons/lock.svg";
import { useDispatch, useSelector } from "react-redux";
import { setErrorCode } from "../slices/login";
import MaskLogo from "../assets/icons/mask-logo.svg";

const Login = () => {
  /** react */
  const dispatch = useDispatch();
  /** Context */
  const { login } = useContext(AuthContext);
  const { errorMsg, errorCode } = useSelector((state) => state.login);
  /** States */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [canValidate, setCanValidate] = useState(false)
  const [passwordValid, setPasswordValid] = useState(true);
  const enterLogin = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      validate("email", email);
      validate("password", password);
      dispatch(setErrorCode(""));
      if (email && password) {
        login({ email, password });
      }
    }
  };
  const UserLogin = (e, email, password) => {
    setCanValidate(true)
    e.preventDefault();
    validate("email", email);
    validate("password", password);
    dispatch(setErrorCode(""));
    if (email && password) {
      login({ email, password });
    }
  };

  const validate = (type, value) => {    
    dispatch(setErrorCode(""));
    if (type === "email") {
      setEmail(value);
      if (value === "") {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
    } else if (type === "password") {
      setPassword(value);
      if (value === "") {
        setPasswordValid(false);
      } else {
        setPasswordValid(true);
      }
    }
  };

  return (
    <>
      <div
        className="bg-dark d-flex align-items-center"
        style={{
          backgroundImage: `url(${BackgroundDots})`,
          backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <LoginBox>
          <div className="row h-100">
            <div className="col d-flex">
              <div style={{ marginLeft: "85px", marginTop: "48px" }}>
                <div class="login-container">
                  <div className="d-flex justify-content-center font-ethno-light text-white mb-4" style={{fontSize: 29}}>
                    LEVIAS ID
                  </div>
                  <form>
                    <div
                      onKeyDown={enterLogin}
                      className="d-flex justify-content-center"
                    >
                      <div className="position-relative">
                        <LoginInput
                          type="email"
                          placeholder="メールアドレス を入力してください"
                          width="392.99px"
                          height="55.78px"
                          onChange={(e) => {setEmail(e.target.value); canValidate && validate("email", e.target.value)}}
                          onBlur={(e) => canValidate && validate("email", e.target.value)}
                        />
                        <div
                          className="position-absolute user-icon translate-middle"
                          style={{ left: "30px", top: "45%" }}
                        >
                          <img src={UserIcon} alt="user-icon" />
                        </div>
                      </div>
                    </div>
                  </form>
                  {!emailValid ? (
                    <div className="error-valid-login mt-2">
                      メールアドレスを入力してください
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div class="login-container" style={{marginTop: '41px'}}>
                  <form>
                    <div
                      onKeyDown={enterLogin}
                      className="d-flex justify-content-center"
                      // style={{
                      //   marginTop: "41.34px",
                      // }}
                    >
                      <div className="position-relative">
                        <LoginInput
                          type="password"
                          placeholder="パスワード を入力してください"
                          width="392.99px"
                          height="55.78px"
                          onChange={(e) => { setPassword(e.target.value);canValidate && validate("password", e.target.value)}}
                          onBlur={(e) => canValidate && validate("password", e.target.value)}
                        />
                        <div
                          className="position-absolute user-icon translate-middle"
                          style={{ left: "30px", top: "45%" }}
                        >
                          <img src={LockIcon} alt="user-icon" />
                        </div>
                      </div>
                    </div>
                  </form>
                  
                  {!passwordValid ? (
                    <div className="error-valid-login mt-2">
                      パスワードを入力してください
                      {/* パスワードを入力してください */}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {errorCode === 401 || errorCode === 422 ? (
                    <div className="error-valid-login mt-2">{errorMsg}</div>
                  ) : (
                    <div></div>
                  )}
                </div>

                {/* <Link to="/" className="link"> */}
                <div
                  onClick={(e) => UserLogin(e, email, password)}
                  style={{
                    cursor: "pointer",
                    marginTop: "71.65px",
                    height: "51px",
                  }}
                  className="row button-text"
                >
                  <div
                    className="fs-30-px font-ethno-light d-flex justify-content-center px-5"
                    // style={{ marginTop: "0.5%" }}
                  >
                    SIGN IN
                  </div>
                </div>
                {/* </Link> */}
              </div>
            </div>
            <div className="col d-flex flex-column justify-content-center">
              <div style={{ marginLeft: "15%" }}>
                <div className="row">
                  {/* <Mask
                    width="300"
                    height="250"
                    id="test-mask"
                    className="d-flex justify-content-center"
                    crop
                  /> */}
                  <img src={MaskLogo} height="167px" className="d-flex justify-content-center" id="task-mask"/>

                  {/* <LoginMask
                    width="300"
                    height="280"
                    id="test-mask"
                    className="d-flex justify-content-center"
                  /> */}
                </div>
                <div className="d-flex justify-content-center" style={{ marginTop: "28px"}}>
                  <div style={{width: 220 }}>
                    <h5 className="text-primary fs-32-px font-ethno-light d-flex justify-content-start">
                      PERSONA
                    </h5>
                    <h5 className="text-primary fs-32-px font-ethno-light d-flex justify-content-end">
                      REALITY
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoginBox>
        {/*<ErrorModal isOpen={errorModal} onAction={() => setErrorModal(false)}>
          {/*errorModal}
                </ErrorModal>*/}
      </div>
    </>
  );
};

export default Login;
